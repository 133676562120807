import style from './Error.module.css'

function Error() {
  return (
    <div className={style.error}>
      <h1>Page not found!</h1>
    </div>
  )
}

export default Error
