import style from "./MonsterHunt.module.css"
import Gallery from "react-photo-gallery"

function MonsterHunt() {
	const photos = [
		{
			src: "img/monsterhunt/battle.gif",
			width: 800,
			height: 614,
		},
		{
			src: "img/monsterhunt/forest.jpg",
			width: 1412,
			height: 1079,
		},
		{
			src: "img/monsterhunt/hospital.jpg",
			width: 1245,
			height: 1079,
		},
		{
			src: "img/monsterhunt/mechanics.jpg",
			width: 1415,
			height: 1080,
		},

		{
			src: "img/monsterhunt/dinner.gif",
			width: 800,
			height: 611,
		},
		{
			src: "img/monsterhunt/outside.jpg",
			width: 1415,
			height: 1079,
		},
		{
			src: "img/monsterhunt/walking.jpg",
			width: 1412,
			height: 1071,
		},
		{
			src: "img/monsterhunt/scores.jpg",
			width: 1416,
			height: 1080,
		},
		{
			src: "img/monsterhunt/puzzle.gif",
			width: 800,
			height: 615,
		},
	]
	return (
		<div className={style.wrapper}>
			<iframe
				className={style.iframe}
				src="https://dylannick-public-assets.s3.us-east-2.amazonaws.com/monster/www/index.html"
				width="816"
				height="624"
				allowFullScreen
			>
				Sorry your browser does not support inline frames.
			</iframe>
			<div className={style.playMe}>
				<p>🔉 Enable sound</p>
			</div>

			<img src="img/monsterhunt/characters.png" />

			<div class={style.body}>
				<div className={style.table}>
					<div>
						<p>Release</p>
						<p>March 2016</p>
					</div>
					<div>
						<p>Platform</p>
						<p>Browser, Windows, macOS</p>
					</div>
					<div>
						<p>Genre</p>
						<p>2D RPG</p>
					</div>
					<div>
						<p>Length</p>
						<p>3 Hours</p>
					</div>
				</div>

				<div class={style.divider} />

				<p>
					My very first completed RPG. The main characters seek to escape their
					money problems by hunting down a rare bird.
				</p>

				<p>
					<strong>Features</strong>
				</p>

				<ul>
					<li>
						Customizable characters! Able to upgrade your weapons on the fly and
						characters will change appearance based on what armor they are
						wearing.
					</li>
					<li>
						Minigames! Fighting monsters are not the only way to earn money.
						Take a side gig as a waiter or even a post man.
					</li>
					<li>
						Puzzles! A grand total of 4 dungeons each filled with their own
						unique gimmicks.
					</li>
					<li>
						Secrets! There is a lot hidden throughout the levels as well as 4
						additional characters. Can you find them all?
					</li>
				</ul>
				<div class={style.divider} />
				<div>
					Download for{" "}
					<a href="https://dylanblake.ca/download/MonsterHuntWIN.zip" download>
						Windows
					</a>{" "}
					and{" "}
					<a href="https://dylanblake.ca/download/MonsterHuntMAC.zip" download>
						macOS
					</a>
				</div>
			</div>

			<Gallery photos={photos} direction="row" targetRowHeight={500} />
			<img
				src="img/monsterhunt/garuda.png"
				style={{ margin: "100px auto 50px", display: "block" }}
				width={191}
				height={153}
			/>
		</div>
	)
}

export default MonsterHunt
