import style from './Projects.module.css'
import { ProjectTile } from './../components/ProjectTile'

function Games() {
  return (
    <div className={style.main}>
      <h3 className={style.lede}>
        Hello 👋 I am a software engineer looking for my next challenge.
        <br />
        Here is a collection of some of the projects I have worked on.
      </h3>
      <div className={style.gridContainer}>
        <ProjectTile url="monsterhunt" image="/img/thumbnails/monster-hunt.jpg" color="#693ebf" />
        <ProjectTile url="corvos" image="/img/thumbnails/corvos.jpg" color="#e56053" />
        <ProjectTile url="kazooie" image="/img/thumbnails/kazooie.jpg" color="#37393e" />
      </div>
    </div>
  )
}

export default Games
