import style from './Header.module.css'
import clsx from 'clsx'
import { NavLink, Link } from 'react-router-dom'

export default props => (
  <div className={clsx(props.className, style.header)}>
    <h1>
      <Link to="/">Dylan Blake</Link>
    </h1>
    <nav>
      <NavLink exact to="/" activeClassName={style.active}>
        <p>Projects</p>
      </NavLink>
      <NavLink to="/about" activeClassName={style.active}>
        <p>About</p>
      </NavLink>
    </nav>
  </div>
)
