import style from './Layout.module.css'
import Header from './Header'
import Footer from './Footer'

function Layout(props) {
  return (
    <div className={style.page}>
      <Header />
      <main className={style.main}>{props.children}</main>
      <Footer />
    </div>
  )
}

export default Layout
