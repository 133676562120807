import style from './Footer.module.css'
import clsx from 'clsx'

function Footer(props) {
  return (
    <div className={clsx(style.footer, props.className)}>
      <div className={style.inner}>
        <a href="https://github.com/midnightzelda" target="_blank">
          Github
        </a>
        <a href="https://www.linkedin.com/in/dylan-blake-45280212b/" target="_blank">
          LinkedIn
        </a>
        <a href="https://www.linkedin.com/in/dylan-blake-45280212b/" target="_blank">
          Twitter
        </a>
      </div>
    </div>
  )
}

export default Footer
