import style from './ProjectTile.module.css'
import { Link } from 'react-router-dom'

export const ProjectTile = props => (
  <Link to={props.url} className={style.wrapper} style={{ ...props.style, color: props.color }}>
    <img src={props.image} />
    <div className={style.aspectRatio} />
    <div className={style.overlay} />
  </Link>
)
