import { Route, Switch } from 'react-router-dom'

import About from './pages/About'
import Projects from './pages/Projects'
import Layout from './components/layout/Layout'
import MonsterHunt from './pages/MonsterHunt'
import Dynastia from './pages/Dynastia'
import Kazooie from './pages/Kazooie'
import Error from './pages/Error'

function App() {
  // localhost:3000/
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Projects />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/monsterhunt">
          <MonsterHunt />
        </Route>
        <Route path="/corvos">
          <Dynastia />
        </Route>
        <Route path="/kazooie">
          <Kazooie />
        </Route>
        <Route path="/">
          <Error />
        </Route>
      </Switch>
    </Layout>
  )
}

export default App
