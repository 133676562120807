function Kazooie() {
  return (
    <div>
      <h2>Kazooie Bot</h2>
      <p>Release: 2018</p>
      <p>Last Updated: 2019</p>
      <p>Platform: Discord</p>
      <p>
        A discord bot that provides numerous amount of contact to keep users
        ingaged and give helpful info in games.
      </p>

      <p>Features:</p>
      <ul>
        <li>
          Music Playback: Type /play [Youtube link] to have the dicord bot play
          the audio from a video in chat. Also supports the ability to pausee,
          speed up and slow down.
        </li>
        <li>
          Fortune Telling: Kazooie can tell the future! Just ask it questions
          such as future spouse or if you will win the lottery and Kazzoie will
          give you an answer.
        </li>
        <li>
          Game Suggestions: Not sure what game to play with friends? Just ask
          Kazooie and she will give several suggestions. You are also able to
          filter by genre,price and number of players.
        </li>
        <li>
          Notes: Kazooie can save notes from users to be displayed letter as
          well as show the notes at certain times in the day or after a certain
          amount of minutes.
        </li>
      </ul>
    </div>
  );
}

export default Kazooie;
