import style from './About.module.css'

function About() {
  return (
    <div className={style.wrapper}>
      <div className={style.grow} />
      <div className={style.imageWrapper}>
        <img src="/img/dylan-blake.jpg" alt="Dylan Blake" className={style.photo} />
        <div />
      </div>
      <div className={style.side}>
        <br />
        <br />
        <h1>Hello 👋</h1>
        <p>
          <strong>My name is Dylan Blake, and I am a full-stack software developer from Canada.</strong>
        </p>
        <p>
          I currently work at the Government of Canada, Shared Services maintaining legacy mainframe applications used
          by millions for the Canadian Revenue Agency. I speak JCL, COBOL, JavaScript and python, soon to be fluent in
          TypeScript as well.
        </p>
        <p>
          As a hobby, I enjoy creating 2D video games, phone apps and React websites. I learned how to code before the
          age of 10, and I've created games played by thousands.
        </p>
        <p>
          Say hi at&nbsp;
          <a href="mailto: me@dylanblake.ca">me@dylanblake.ca</a>
        </p>
      </div>
      <div className={style.grow} />
    </div>
  )
}

export default About
