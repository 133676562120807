import style from "./Dynastia.module.css"
import Gallery from "react-photo-gallery"

function Dynastia() {
	const photos = [
		{
			src: "img/dynastia/town.jpg",
			width: 1414,
			height: 1079,
		},
		{
			src: "img/dynastia/fight.gif",
			width: 600,
			height: 460,
		},
		{
			src: "img/dynastia/character.jpg",
			width: 1416,
			height: 1077,
		},
		{
			src: "img/dynastia/level.jpg",
			width: 1414,
			height: 1080,
		},

		{
			src: "img/dynastia/push.gif",
			width: 600,
			height: 459,
		},
		{
			src: "img/dynastia/shop.jpg",
			width: 1415,
			height: 1080,
		},
		{
			src: "img/dynastia/menu.jpg",
			width: 1410,
			height: 1079,
		},
		{
			src: "img/dynastia/forest.jpg",
			width: 1412,
			height: 1079,
		},
		{
			src: "img/dynastia/sneak.gif",
			width: 600,
			height: 458,
		},
	]

	var vid = document.getElementById("myVideo")

	function muteUnmute() {
		vid.muted = !vid.muted
		if (!vid.muted) {
			document.getElementById("muteBtn").src =
				"http://kar2nz.weebly.com/uploads/8/9/2/8/89280494/volumeon_orig.png"
		} else {
			document.getElementById("muteBtn").src =
				"http://kar2nz.weebly.com/uploads/8/9/2/8/89280494/volumeoff_orig.png"
		}
	}

	return (
		<div className={style.wrapper}>
			<iframe
				id="myVideo"
				className={style.iframe}
				src="https://dylannick-public-assets.s3.us-east-2.amazonaws.com/corvos/www/index.html"
				width="816"
				height="624"
				allowFullScreen
			>
				Sorry your browser does not support inline frames.
			</iframe>
			<div className={style.playMe}>
				<button id="muteBtn" onclick="muteUnmute()">
					🔉 Enable sound
				</button>
			</div>

			<img src="img/dynastia/title.png" />

			<div class={style.body}>
				<div className={style.table}>
					<div>
						<p>Release</p>
						<p>No date yet!</p>
					</div>
					<div>
						<p>Platform</p>
						<p> Browser, Windows, macOS</p>
					</div>
					<div>
						<p>Genre</p>
						<p> 2D RPG</p>
					</div>
					<div>
						<p>Length</p>
						<p> 40 Hours</p>
					</div>
				</div>

				<div class={style.divider} />

				<p>4 hour demo of a game that I am planning on releasing on Steam.</p>

				<p>
					<strong>Features:</strong>
				</p>

				<ul>
					<li>
						Over 40 characters! With a main cast of 10 characters and the
						ability to hire mercenaries and shop keepers. There are plenty of
						characters that will fit your playstyle.
					</li>
					<li>
						Customizability! Every piece of equipment can be modified though the
						use of crystals. All crystals have a different effect depending on
						the piece of equipment, some even add new moves to make your
						character more versatile.
					</li>
					<li>
						Side Content! Over 100 side quests as well as plenty of optional
						content such as working at a diner or playing slots at a casino.
					</li>
					<li>
						Built in Economy! Every region has its own fluctuating economy.
						Tired of grinding, try your hand at the market.
					</li>
					<li>
						3 different modes! Tired of playing the story try the arcade mode
						where you go through different floors building your characters or
						try a strategic side of things in conquest mode.
					</li>
				</ul>

				<div class={style.divider} />

				<div>
					Download for{" "}
					<a href="https://dylanblake.ca/download/CorvosWIN.zip" download>
						Windows
					</a>{" "}
					and{" "}
					<a href="https://dylanblake.ca/download/CorvosMAC.zip" download>
						macOS
					</a>
				</div>
			</div>

			<Gallery photos={photos} direction="row" targetRowHeight={500} />
			<img
				src="img/dynastia/fox.jpg"
				style={{ margin: "100px auto 50px", display: "block" }}
				width={107}
				height={131}
			/>
		</div>
	)
}

export default Dynastia
